import { Search } from "feather-icons-react/build/IconComponents";
import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_category = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_category?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_category_menu = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_category_menu", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_category_menu = (bodyParam) => {
    return axiosApi
        .post(API_URL + "category_menu", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_roles = () => {
    return axiosApi
        .get(API_URL + "get_roles", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_category_menu = (param) => {
    return axiosApi
        .get(API_URL + "get_category_menu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_category_menu = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "category_menu/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_category_menu = (param) => {
    return axiosApi
        .delete(API_URL + "category_menu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const categoryMenu = {
    all_category,
    multi_delete_category_menu,
    add_category_menu,
    get_roles,
    view_category_menu,
    edit_category_menu,
    delete_category_menu,
    API_URL,
};

export default categoryMenu;