import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_kindness = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_kindnes?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_kindness = (bodyParam) => {
    return axiosApi
        .post(API_URL + "kindnes_mobile", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_kindness = (param) => {
    return axiosApi
        .get(API_URL + "get_kindnes_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_kindness = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "kindnes_mobile/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_kindness = (param) => {
    return axiosApi
        .delete(API_URL + "kindnes_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_kindness = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_kindnes_mobile", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const kindness = {
    all_kindness,
    multi_delete_kindness,
    delete_kindness,
    add_kindness,
    view_kindness,
    edit_kindness,
    API_URL,
};

export default kindness;