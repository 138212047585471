import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_surah = (size, page, search) => {
    return axiosApi
        .get(API_URL + `list_quran_surah?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_surah = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_quran_surah", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_surah = (param) => {
    return axiosApi
        .delete(API_URL + "quran_surah/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_surah = (bodyParam) => {
    return axiosApi
        .post(API_URL + "quran_surah", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_surah = (param) => {
    return axiosApi
        .get(API_URL + "get_quran_surah/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_surah = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "quran_surah/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const all_ayat = (size, page, search) => {
    return axiosApi
        .get(API_URL + `list_quran_ayat?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_ayat = (param) => {
    return axiosApi
        .get(API_URL + "get_quran_ayat/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_select_surah = () => {
    return axiosApi
        .get(API_URL + "select_quran_surah", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_ayat = (bodyParam) => {
    return axiosApi
        .post(API_URL + "quran_ayat", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_ayat = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "quran_ayat/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_ayat = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_quran_ayat", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_ayat = (param) => {
    return axiosApi
        .delete(API_URL + "quran_ayat/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const AlQuran = {
    all_surah,
    multi_delete_surah,
    delete_surah,
    add_surah,
    view_surah,
    edit_surah,
    all_ayat,
    get_select_surah,
    multi_delete_ayat,
    delete_ayat,
    add_ayat,
    view_ayat,
    edit_ayat,
    API_URL,
};

export default AlQuran;