import { axiosApiH2h, API_URL_H2H } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_saldo_server = (size, page, search) => {
    return axiosApiH2h
        .get(API_URL_H2H + `list_topup_saldo?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_list_bank = () => {
    return axiosApiH2h
        .get(API_URL_H2H + "list_bank", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_one_bank = (params) => {
    return axiosApiH2h
        .get(API_URL_H2H + "select_bank/" + params, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const topup_server = (bodyParam) => {
    return axiosApiH2h
        .post(API_URL_H2H + "request_deposit", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_topup = (param) => {
    return axiosApiH2h
        .get(API_URL_H2H + "get_topup/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const confirm = (param) => {
    return axiosApiH2h
        .put(API_URL_H2H + "confirm/" + param, { status: "1" }, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const SaldoServer = {
    all_saldo_server,
    get_list_bank,
    get_one_bank,
    topup_server,
    get_topup,
    confirm,
    API_URL_H2H,
};

export default SaldoServer;