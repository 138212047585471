import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_roles = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_roles?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_roles = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_roles", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_roles = (bodyParam) => {
    return axiosApi
        .post(API_URL + "roles", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_roles = (param) => {
    return axiosApi
        .get(API_URL + "get_roles/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_roles = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "roles/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_roles = (param) => {
    return axiosApi
        .delete(API_URL + "roles/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const role = {
    all_roles,
    multi_delete_roles,
    add_roles,
    view_roles,
    edit_roles,
    delete_roles,
    API_URL,
};

export default role;