import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_galeri_penyaluran = (param, size, page, search) => {
    return axiosApi
        .get(API_URL + `galeri_kindnes?category=${param}&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_galeri_penyaluran = (bodyParam) => {
    return axiosApi
        .post(API_URL + "add_penyaluran_kindnes", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_galeri_kindnes = (param) => {
    return axiosApi
        .get(API_URL + "galeri_kindnes/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_kindnes = (param) => {
    return axiosApi
        .get(API_URL + "list_threshold_balance?category=" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_galeri_kindnes = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "galeri_kindnes/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const Galeri_beasiswa = {
    all_galeri_penyaluran,
    add_galeri_penyaluran,
    view_galeri_kindnes,
    get_kindnes,
    edit_galeri_kindnes,
    API_URL,
};

export default Galeri_beasiswa;