import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import History_kindness from "../../services/HistoryKindness"
import online_sale from "../../services/OnlineSaleService"
import { IMAGE_URL } from "../../helpers/api_helper"

const OnlineSaleList = () => {
    const [list_sales, setListSales] = useState([])
    const [list_sales_detail, setListSalesDetail] = useState([]);
    const [view_sales, setViewSales] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    const itemListStyle = {
        margin: '20px'
    };

    const itemStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        paddingBottom: '10px',
        position: 'relative'
    };

    const itemImageStyle = {
        width: '60px',
        height: '60px',
        marginRight: '10px'
    };

    const itemDetailsStyle = {
        display: 'flex',
        flexDirection: 'column'
    };

    const itemNameStyle = {
        fontSize: '14px',
        fontWeight: 'bold'
    };

    const itemPriceQuantityStyle = {
        fontSize: '13px'
    };

    const hrStyle = {
        position: 'absolute',
        left: '10px',
        right: '10px',
        bottom: '-17px',
        borderBottom: '1px solid #ccc'
    };

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN") && !localStorage.getItem("authUser").includes("ROLE_KASIR")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await online_sale.all_sales(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                total_payment: numberFormat(item.total_payment),
                count_item: `+${item.count_item - 1} produk lainnya`,
                product_image: <img src={IMAGE_URL + item.product_image} alt="" className="img-fluid" style={{ height: "60px", padding: "5px" }} />,
                status_pay: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.status_pay)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.status_pay)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListSales(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "primary"
            case 3:
                return "success"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Pembayaran Diterima"
            case 3:
                return "Pesanan Diterima"
            default:
                return "Unknown"
        }
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Product Name',
            selector: row => row.item_name,
            sortable: true,
            width: '33%',
        },
        {
            name: 'Product Image',
            selector: row => row.product_image,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Total Barang',
            selector: row => row.count_item,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Total Bayar',
            selector: row => row.total_payment,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Status',
            selector: row => row.status_pay,
            sortable: true,
            width: '12%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        let buttonIcon;
        let buttonClass;
        if (row.status === 3) {
            buttonIcon = <i className="bx bx-show font-size-16 align-middle"></i>;
            buttonClass = "btn btn-secondary waves-effect waves-light";
        } else {
            buttonIcon = <i className="bx bx-send font-size-16 align-middle"></i>;
            buttonClass = "btn btn-primary waves-effect waves-light";
        }
        return (
            <div
                style={{
                    textAlign: "left",
                }}>
                <button type="button" className={buttonClass} onClick={() => { handleClickView(cell.id) }} data-toggle="modal">{buttonIcon}</button>
            </div>
        );
    }

    function handleClickView(id) {
        online_sale.view_sales(id).then(
            (data) => {
                var obj = data;
                setview_modal(!view_modal)
                removeBodyCss()
                Object.assign(obj, { total_payment: numberFormat(obj.total_payment) })
                Object.assign(obj, { status: obj.status_pay })
                if (data.status_pay === 2) {
                    Object.assign(obj, { status_pay: <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Pembayaran Diterima </span> });
                } else if (data.status_pay === 3) {
                    Object.assign(obj, { status_pay: <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Pesanan Diterima </span> });
                }
                setViewSales(data)
                setListSalesDetail(data.salesDetails);
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        }
    }

    function handleClickComplete(id) {
        let bodyParam = {
            status_pay: 3
        };

        online_sale.edit_status(id, bodyParam).then(
            (data) => {
                handleClickClose("view")
                initData(currentSize, currentPage, searchTerm)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_sales)
    };

    document.title = `History Penjualan Online | ${webConfig[0].name} - ${webConfig[0].title}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Penjualan Online" />
                    <Row className="mb-3">
                        <ButtonContainer
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="3">
                                ID
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                No Penjualan
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                #{view_sales.no_sales}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                tanggal
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.tanggal}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Total Bayar
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.total_payment}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Tipe
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.type}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Status
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.status_pay}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Created Date
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_sales.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Updated Date
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_sales.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="row">
                            <Col xl="4">
                                <strong>Detail List Barang :</strong><br /><br />
                            </Col>
                            {list_sales_detail.map((item, index) => (
                                <div style={itemStyle} key={index}>
                                    <img src={IMAGE_URL + item.product_image} alt={item.item_name} style={itemImageStyle} />
                                    <div style={itemDetailsStyle}>
                                        <div style={itemNameStyle}>{item.item_name}</div>
                                        <div style={itemPriceQuantityStyle}>{numberFormat(item.price)}</div>
                                        <div style={itemPriceQuantityStyle}>Jumlah : {item.qty}</div>
                                    </div>
                                    <hr style={hrStyle} />
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            {view_sales.status !== 3 && (
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() => handleClickComplete(view_sales.id)}
                                >
                                    Pesanan Telah Diterima
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default OnlineSaleList;
