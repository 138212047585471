import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_submenu = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_submenu?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_submenu = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_submenu", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_submenu = (param) => {
    return axiosApi
        .delete(API_URL + "submenu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_submenu = (bodyParam) => {
    return axiosApi
        .post(API_URL + "submenu", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_submenu = (param) => {
    return axiosApi
        .get(API_URL + "get_submenu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_submenu = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "submenu/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const menu_data = () => {
    return axiosApi
        .get(API_URL + "get_menu_name", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const menu = {
    all_submenu,
    multi_delete_submenu,
    delete_submenu,
    add_submenu,
    view_submenu,
    edit_submenu,
    menu_data,
    API_URL,
};

export default menu;