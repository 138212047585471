import React from "react";
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';

const DataTableContainer = ({
    columns,
    data,
}) => {
    return (
        <React.Fragment>
            <DataTable
                columns={columns}
                data={data}
                highlightOnHover
                striped
                responsive
            />
        </React.Fragment>
    );
};

DataTableContainer.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};

export default DataTableContainer;
