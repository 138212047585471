import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_product = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_product?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_product = (bodyParam) => {
    return axiosApi
        .post(API_URL + "product_mobile", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_product = (param) => {
    return axiosApi
        .get(API_URL + "get_product_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_product = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "product_mobile/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_product = (param) => {
    return axiosApi
        .delete(API_URL + "product_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_product = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_product_mobile", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const product = {
    all_product,
    multi_delete_product,
    delete_product,
    add_product,
    view_product,
    edit_product,
    API_URL,
};

export default product;