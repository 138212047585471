import React, { useEffect, useState, useRef } from 'react';

import ReactApexChart from "react-apexcharts"
import dashboard from "../../services/DashboardService"

//import Breadcrumbs
import Breadcrumb2 from "../../components/Common/Breadcrumb2";

import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    FormGroup,
    Label,
    InputGroup
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

/** import Mini Widget data */
import { WidgetsData } from "../../common/data/dashboard";

const options = {
    chart: {
        height: 50,
        type: "line",
        toolbar: { show: false },
        sparkline: {
            enabled: true
        }
    },
    colors: ["#5156be"],
    stroke: {
        curve: "smooth",
        width: 2,
    },
    xaxis: {
        labels: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            show: false,
        },
    },
    tooltip: {
        fixed: {
            enabled: false,
        },
        x: {
            show: false,
        },
        y: {
            title: {
                formatter: function (seriesName) {
                    return "";
                },
            },
        },
        marker: {
            show: false,
        },
    },
};

const Dashboard = () => {
    const flatpickrRef = useRef(null);
    const [selectedDates, setSelectedDates] = useState([null, null]);
    const [saldo_h2h, setSaldoH2h] = useState("");
    const [saldo_member, setSaldoMember] = useState("");
    const [saldo_anggota, setSaldoAnggota] = useState("");
    const [saldo_beasiswa, setSaldoBeasiswa] = useState("");
    const [saldo_tersalurkan_beasiswa, setSaldoTersalurkanBeasiswa] = useState("");
    const [transaction_success, setTransactionSuccess] = useState("");
    const [transaction_failed, setTransactionFailed] = useState("");
    const [profits, setProfits] = useState("");
    const [user_verify, setUserVerify] = useState("");
    const [anggota_verify, setAnggotaVerify] = useState("");
    const [member_not_verify, setMemberNotVerify] = useState("");
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    //meta title
    document.title = `Dashboard | ${webConfig[0].name} - ${webConfig[0].title}`;

    useEffect(() => {
        const controller = new AbortController();
        const currentDate = new Date();
        const end_date = currentDate.toISOString().split('T')[0];
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit' };
        const start_date = startOfMonth.toLocaleString('en-CA', options);
        setSelectedDates([start_date, end_date]);
        initData(start_date, end_date);
        return () => controller.abort();
    }, [])

    const handleSearchButtonClick = () => {
        const selectedDates = flatpickrRef.current?.flatpickr.selectedDates;
        const formattedDates = selectedDates.map(date => {
            const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit' };
            const [day, month, year] = date.toLocaleDateString('en-GB', options).split('/');
            return `${year}-${month}-${day}`;
        });
        const start_date = formattedDates[0];
        const end_date = formattedDates[1];
        initData(start_date, end_date);
    };

    function initData(start_date, end_date) {
        const numberFormat = (value) =>
            new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value).replace(',00', '');

        dashboard.findSaldoH2h().then(
            (response) => {
                setSaldoH2h(numberFormat(response.data.deposit));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findSaldoMember().then(
            (response) => {
                setSaldoMember(numberFormat(response.data.balance));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findSaldoAnggota().then(
            (response) => {
                setSaldoAnggota(numberFormat(response.data.balance));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findSaldoBeasiswa(start_date, end_date).then(
            (response) => {
                setSaldoBeasiswa(numberFormat(response.data.nominal));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findSaldoTersalurkanBeasiswa(start_date, end_date).then(
            (response) => {
                setSaldoTersalurkanBeasiswa(numberFormat(response.data.nominal));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findTransactionSuccess(start_date, end_date).then(
            (response) => {
                setTransactionSuccess(numberFormat(response.data.amount));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findTransactionFailed(start_date, end_date).then(
            (response) => {
                setTransactionFailed(numberFormat(response.data.amount));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findProfits(start_date, end_date).then(
            (response) => {
                setProfits(numberFormat(response.data.amount));
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findMemberVerify("User").then(
            (response) => {
                setUserVerify(response.data.count);
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findMemberVerify("Anggota").then(
            (response) => {
                setAnggotaVerify(response.data.count);
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )

        dashboard.findMemberNotVerify().then(
            (response) => {
                setMemberNotVerify(response.data.count);
                return Promise.resolve();
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumb2 breadcrumbItem="Dashboard" />
                    <Row>
                        <Label>Filter by Date :</Label>
                        <Col xl={3} md={5}>
                            <FormGroup className="col-sm">
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="yyyy-mm-dd to yyyy-mm-dd"
                                        options={{
                                            mode: "range",
                                            dateFormat: "Y-m-d"
                                        }}
                                        value={selectedDates}
                                        ref={flatpickrRef}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xl={4} md={6}>
                            <button type="button" className="btn btn-success" onClick={handleSearchButtonClick} data-toggle="modal"><i className="bx bx-search font-size-20 align-middle"></i> Search</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Server H2H</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_h2h}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Deposit Users</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_member}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Deposit Anggota</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_anggota}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Transaction Success</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {transaction_success}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Transaction Failed</span>
                                            <h4 className="mb-3">
                                                {transaction_failed}
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Total Keuntungan</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {profits}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">User Sudah Verifikasi</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {user_verify}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Anggota Sudah Verifikasi</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {anggota_verify}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={9}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Member Belum Verifikasi</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {member_not_verify}
                                                </span>
                                            </h4>
                                        </Col>
                                        <Col xs={3}>
                                            <ReactApexChart
                                                options={options}
                                                series={[{ data: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2] }]}
                                                type="line"
                                                className="apex-charts"
                                                dir="ltr"
                                                height={30}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Beasiswa Tahfidz</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_beasiswa}
                                                </span>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Beasiswa Tahfidz Tersalurkan</span>
                                            <h4 className="mb-3">
                                                {saldo_tersalurkan_beasiswa}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Teras Koperasi</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_beasiswa}
                                                </span>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Teras Koperasi Tersalurkan</span>
                                            <h4 className="mb-3">
                                                {saldo_tersalurkan_beasiswa}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Wakaf Produktif</span>
                                            <h4 className="mb-3">
                                                <span className="counter-value">
                                                    {saldo_beasiswa}
                                                </span>
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3} md={6}>
                            <Card className="card-h-100">
                                <CardBody>
                                    <Row className="align-items-center">
                                        <Col xs={12}>
                                            <span className="text-muted mb-3 lh-1 d-block text-truncate">Saldo Wakaf Produktif Tersalurkan</span>
                                            <h4 className="mb-3">
                                                {saldo_tersalurkan_beasiswa}
                                            </h4>
                                        </Col>
                                    </Row>
                                    <div className="text-nowrap">
                                        <span className={"badge badge-soft-success"}>
                                            +$20.9k
                                        </span>
                                        <span className="ms-1 text-muted font-size-13">Since last month</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;