import React from "react";
import { Button, Input, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';

const DataTableContainer = ({
    isButtonAdd,
    isButtonDelete,
    isButtonSync,
    isButtonUpload,
    textButtonAdd,
    handleClickAdd,
    handleClickDeleteSelected,
    handleClickSync,
    handleClickUpload
}) => {
    return (
        <React.Fragment>
            <Col md="10">
                {isButtonAdd && (
                    <Button id="btnAdd" onClick={handleClickAdd} className="btn btn-success waves-effect btn-label waves-light">
                        <i className="bx bx-plus label-icon"></i> {textButtonAdd}
                    </Button>
                )}
                {isButtonSync && (
                    <Button id="btnSync" onClick={handleClickSync} className="btn btn-primary waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                        <i className="bx bx-sync label-icon"></i> Synchronize
                    </Button>
                )}
                {isButtonUpload && (
                    <Button id="btnUpload" onClick={handleClickUpload} className="btn btn-info waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                        <i className="bx bx-upload label-icon"></i> Upload
                    </Button>
                )}
                {isButtonDelete && (
                    <Button id="btnDelete" onClick={handleClickDeleteSelected} className="btn btn-danger waves-effect btn-label waves-light" style={{ marginLeft: '16px' }}>
                        <i className="bx bx-trash label-icon"></i> Hapus
                    </Button>
                )}
            </Col>
        </React.Fragment>
    );
};

DataTableContainer.propTypes = {
    isButtonAdd: PropTypes.bool,
    isButtonDelete: PropTypes.bool,
    isButtonSync: PropTypes.bool,
    isButtonUpload: PropTypes.bool,
    handleClickAdd: PropTypes.func,
    handleClickDeleteSelected: PropTypes.func,
    handleClickSync: PropTypes.func,
    handleClickUpload: PropTypes.func
};

export default DataTableContainer;
