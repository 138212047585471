import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_history = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_list_topup?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const all_history_approve = () => {
    return axiosApi
        .get(API_URL + "get_list_topup_approve", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_topup = (param) => {
    return axiosApi
        .get(API_URL + "get_list_topup/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_topup_approve = (param) => {
    return axiosApi
        .get(API_URL + "get_list_topup_approve/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const approve = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "approve_topup/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const History_topup = {
    all_history,
    all_history_approve,
    view_topup,
    view_topup_approve,
    approve,
    API_URL,
};

export default History_topup;