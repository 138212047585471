import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_history_beasiswa = (size, page, search) => {
    return axiosApi
        .get(API_URL + `history_kindnes?category=Beasiswa Tahfidz&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const all_history_teras = (size, page, search) => {
    return axiosApi
        .get(API_URL + `history_kindnes?category=Teras Koperasi&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const all_history_wakaf = (size, page, search) => {
    return axiosApi
        .get(API_URL + `history_kindnes?category=Wakaf Produktif&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const History_kindness = {
    all_history_beasiswa,
    all_history_teras,
    all_history_wakaf,
    API_URL,
};

export default History_kindness;