import React, { useEffect, useState, useRef } from "react";
import { Container, Input, Row, Col, FormGroup, InputGroup, Label } from "reactstrap";
import Flatpickr from "react-flatpickr"
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import History_infaq from "../../services/HistoryInfaq"
import History_saldo from "../../services/HistorySaldo"
import { ModalInfo } from "../../components/Common/ModalCustoms";

const HistorySaldo = () => {
    const [list_history, setListHistory] = useState([])
    const flatpickrRef = useRef(null);
    const [selectedDates, setSelectedDates] = useState([null, null]);
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [start_date, setStartDate] = useState('')
    const [end_date, setEndDate] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        const currentDate = new Date();
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        const end_date = currentDate.toISOString().split('T')[0];
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit' };
        const start_date = startOfMonth.toLocaleString('en-CA', options);
        setStartDate(start_date)
        setEndDate(end_date)
        setSelectedDates([start_date, end_date]);
        initData(start_date, end_date, 10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const handleSearchButtonClick = () => {
        const selectedDates = flatpickrRef.current?.flatpickr.selectedDates;
        const formattedDates = selectedDates.map(date => {
            const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit' };
            const [day, month, year] = date.toLocaleDateString('en-GB', options).split('/');
            return `${year}-${month}-${day}`;
        });
        const start_date = formattedDates[0];
        const end_date = formattedDates[1];
        if (!start_date || !end_date) {
            ModalInfo(
                "Error",
                "Filter by Date cannot be empty! Please select Filter by Date and click search again",
                "error",
                false
            )
            return;
        } else {
            setStartDate(start_date)
            setEndDate(end_date)
            initData(start_date, end_date, currentSize, currentPage, searchTerm);
        }
    };

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (start_date, end_date, size, page, search) => {
        try {
            const result = await History_saldo.all_history(start_date, end_date, size, page, search)
            const formattedData = result.data.map((item, index) => {
                const color = (item.operator === "+") ? "green" : "red";
                return {
                    ...item,
                    no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                    nominal: <span style={{ color: color }}>{item.operator}{numberFormat(item.nominal)}</span>,
                    saldo_awal: numberFormat(item.saldo_awal),
                    saldo_akhir: numberFormat(item.saldo_akhir),
                    type: item.type.toUpperCase(),
                    transaction_date: Moment(item.transaction_date).utc().format('yyyy-MM-DD hh:mm:ss')
                }
            })
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListHistory(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Full Name',
            selector: row => row.users.full_name,
            sortable: true,
            width: '13%',
        },
        {
            name: 'Email',
            selector: row => row.users.email,
            sortable: true,
            width: '13%',
        },
        {
            name: 'Phone',
            selector: row => row.users.phone,
            sortable: true,
            width: '13%',
        },
        {
            name: 'Nominal',
            selector: row => row.nominal,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Saldo Awal',
            selector: row => row.saldo_awal,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Saldo Akhir',
            selector: row => row.saldo_akhir,
            sortable: true,
            width: '11%',
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            width: '8%',
        },
        {
            name: 'Transaction Date',
            selector: row => row.transaction_date,
            sortable: true,
            width: '14%',
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(start_date, end_date, size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(start_date, end_date, currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(start_date, end_date, currentSize, currentPage, event.target.value)
        setFiltered(list_history)
    };

    document.title = `History Saldo Member | ${webConfig[0].name} - ${webConfig[0].title}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="History" breadcrumbItem="Saldo Member" />
                    <Row className="mb-3">
                        <Label>Filter by Date :</Label>
                        <Col xl={3} md={2}>
                            <FormGroup className="col-sm">
                                <InputGroup>
                                    <Flatpickr
                                        className="form-control d-block"
                                        placeholder="yyyy-mm-dd to yyyy-mm-dd"
                                        options={{
                                            mode: "range",
                                            dateFormat: "Y-m-d"
                                        }}
                                        value={selectedDates}
                                        ref={flatpickrRef}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col xl={7} md={2}>
                            <button type="button" className="btn btn-success" onClick={handleSearchButtonClick} data-toggle="modal"><i className="bx bx-search font-size-20 align-middle"></i> Search</button>
                        </Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
            </div >
        </React.Fragment >
    )
};

export default HistorySaldo;
