import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import section from "../../services/SectionService"
import Class from "../../services/ClassService"
import { useFormik } from "formik";
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms";

const ListClassSettings = () => {
    const [list_class, setListClass] = useState([])
    const [get_section, setSection] = useState([])
    const [view_class, setViewClass] = useState("");
    const [edit_class, setEditClass] = useState("");
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden";
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search) => {
        try {
            let sections = "";

            const authUser = JSON.parse(localStorage.getItem("authUser"));

            if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
                !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
            ) {
                sections = authUser.sections[0];
            }

            const result = await Class.all_class(size, page, search, sections)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListClass(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal" style={{ marginRight: "10px" }}>
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Nama Kelas',
            selector: row => row.class_name,
            sortable: true,
            width: '25%',
        },
        {
            name: 'Tingkat',
            selector: row => row.level,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Created Date',
            selector: row => row.created_date,
            sortable: true,
        },
        {
            name: 'Updated Date',
            selector: row => row.updated_date,
            sortable: true,
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden";
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
        getSection()
    };

    function getSection() {
        let sections = "";

        const authUser = JSON.parse(localStorage.getItem("authUser"));

        if (authUser.roles.includes("ROLE_PEMBAYARAN") &&
            !(authUser.roles.includes("ROLE_DEVELOPER") || authUser.roles.includes("ROLE_ADMIN"))
        ) {
            sections = authUser.sections[0];
        }

        Class.get_section(sections).then(
            (response) => {
                if (response.data.length === 1) {
                    validationAddType.setFieldValue("section_id", response.data[0].id)
                    validationEditType.setFieldValue("section_id", response.data[0].id)
                    setSection(response.data)
                } else {
                    setSection(response.data)
                }
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await Class.view_class(id)
            const updatedData = { ...data };
            setViewClass(updatedData)
            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        Class.view_class(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditClass(data)
                getSection()
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))
        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        } else {
            ModalConfirm(
                "Are you sure?",
                "You won't be able to revert this!",
                "warning",
                "list class settings",
                () => processClickDeleteSelected(),
            )
        }
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await Class.multi_delete_class({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "list class settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await Class.delete_class(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_class)
    };

    document.title = `Daftar Kelas Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            class_name: "",
            level: "",
            section_id: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await Class.add_class(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            class_name: edit_class.class_name,
            level: edit_class.level,
            section_id: edit_class.section_id,
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await Class.edit_class(edit_class.id, values)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Kesiswaan" breadcrumbItem="Daftar Kelas" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            textButtonAdd="Tambah"
                            isButtonDelete={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={"Nama Kelas"}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Nama Kelas</Label>
                                <Input
                                    type="text"
                                    name="class_name"
                                    id="class_name"
                                    placeholder="Enter class name"
                                    value={validationAddType.values.class_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tingkat</Label>
                                <Input
                                    type="number"
                                    name="level"
                                    id="level"
                                    placeholder="Enter level"
                                    value={validationAddType.values.level}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationAddType.values.section_id}
                                    onChange={validationAddType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Name Kelas</Label>
                                <Input
                                    type="text"
                                    name="class_name"
                                    id="class_name"
                                    placeholder="Enter class name"
                                    value={validationEditType.values.class_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tingkat</Label>
                                <Input
                                    type="number"
                                    name="level"
                                    id="level"
                                    placeholder="Enter level"
                                    value={validationEditType.values.level || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3" hidden={
                                localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                !(
                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                )
                            }>
                                <Label className="form-label">Bagian</Label>
                                <Input
                                    type="select"
                                    name="section_id"
                                    id="section_id"
                                    value={validationEditType.values.section_id}
                                    onChange={validationEditType.handleChange}
                                    disabled={
                                        localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                    }
                                    style={{
                                        backgroundColor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'lightgray'
                                                : 'white', // Set the color based on whether the input is disabled
                                        cursor:
                                            localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                                !(
                                                    localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                                    localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                                )
                                                ? 'not-allowed'
                                                : 'default', // Set the cursor style based on whether the input is disabled
                                    }}
                                    required
                                >
                                    {localStorage.getItem("authUser").includes("ROLE_PEMBAYARAN") &&
                                        !(
                                            localStorage.getItem("authUser").includes("ROLE_DEVELOPER") ||
                                            localStorage.getItem("authUser").includes("ROLE_ADMIN")
                                        )
                                        ? (
                                            <div></div>
                                        ) : (
                                            <option value="">Select section</option>
                                        )
                                    }
                                    {get_section.map((section, index) => (
                                        <option value={section.id} key={index}>{section.section_name}</option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal >
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_class.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Nama Kelas
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_class.class_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Tingkat
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_class.level}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_class.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_class.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default ListClassSettings;
