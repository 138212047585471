import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_kebaikan = (size, page, search) => {
    return axiosApi
        .get(API_URL + `list_kindnes?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_kebaikan = (bodyParam) => {
    return axiosApi
        .post(API_URL + "kindnes", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_kebaikan = (param) => {
    return axiosApi
        .get(API_URL + "get_kindnes/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_kebaikan = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "kindnes/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_kebaikan = (param) => {
    return axiosApi
        .delete(API_URL + "kindnes/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_kebaikan = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_kindnes", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const kebaikan = {
    all_kebaikan,
    add_kebaikan,
    view_kebaikan,
    edit_kebaikan,
    delete_kebaikan,
    multi_delete_kebaikan,
    API_URL,
};

export default kebaikan;