import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_menu = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_menu?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_menu = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_menu", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_menu = (param) => {
    return axiosApi
        .delete(API_URL + "menu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_menu = (bodyParam) => {
    return axiosApi
        .post(API_URL + "menu", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_menu = (param) => {
    return axiosApi
        .get(API_URL + "get_menu/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_menu = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "menu/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const icon_data = () => {
    return axiosApi
        .get(API_URL + "get_icon", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const category_data = () => {
    return axiosApi
        .get(API_URL + "get_category_name", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const menu = {
    all_menu,
    multi_delete_menu,
    delete_menu,
    add_menu,
    view_menu,
    edit_menu,
    icon_data,
    category_data,
    API_URL,
};

export default menu;