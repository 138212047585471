import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_pending_transaction = () => {
    return axiosApi
        .get(API_URL + "get_all_pending_transaction", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const approve_pending = (param) => {
    return axiosApi
        .put(API_URL + "approve_pending_transaction/" + param, "", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const pendingTransaction = {
    all_pending_transaction,
    approve_pending,
    API_URL,
};

export default pendingTransaction;