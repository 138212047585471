import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_main = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_main_menu?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_main = (bodyParam) => {
    return axiosApi
        .post(API_URL + "main_menu_mobile", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_main = (param) => {
    return axiosApi
        .get(API_URL + "get_main_menu_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_main = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "main_menu_mobile/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_main = (param) => {
    return axiosApi
        .delete(API_URL + "main_menu_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_main_menu = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_main_menu_mobile", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const main_menu = {
    all_main,
    multi_delete_main_menu,
    delete_main,
    add_main,
    view_main,
    edit_main,
    API_URL,
};

export default main_menu;