import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import { IMAGE_URL } from "../../helpers/api_helper";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SaldoServer from "../../services/SaldoServerService"
import postpaid from "../../services/PostpaidService"
import prepaid from "../../services/PrepaidService"
import { useFormik } from "formik"
import { ModalConfirmation, ModalInfo } from "../../components/Common/ModalCustoms";

const PrepaidSettings = () => {
    const [list_prepaid, setListPrepaid] = useState([])
    const [view_prepaid, setViewPrepaid] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await prepaid.all_prepaid_product(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                price: numberFormat(item.price),
                profits: numberFormat(item.h2h_management_profit.profits),
                seller_product_status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.seller_product_status)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.seller_product_status)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListPrepaid(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const handleClickSync = (event, values) => {
        ModalConfirmation(
            "You want to sync this data?",
            "",
            "warning",
            "prepaid settings",
            () => processClickSynchronize(),
        )
    }

    const processClickSynchronize = async () => {
        try {
            await prepaid.synchronize_data()
            ModalInfo(
                "Success",
                "Updated product prepaid success.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    }

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case true:
                return "success"
            case false:
                return "danger"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case true:
                return "Product Active"
            case false:
                return "Product Not Active"
            default:
                return "Unknown"
        }
    };

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '6%',
        },
        {
            name: 'Product Name',
            selector: row => row.product_name,
            sortable: true,
            width: '26%',
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
            width: '9%',
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            width: '8%',
        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Profit',
            selector: row => row.profits,
            sortable: true,
            width: '7%',
        },
        {
            name: 'SKU Code',
            selector: row => row.buyer_sku_code,
            sortable: true,
            width: '10%',
        },
        {
            name: 'Seller Status',
            selector: row => row.seller_product_status,
            sortable: true,
            width: '12%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            <div
                style={{
                    textAlign: "left",
                }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal"><i className="bx bx-show font-size-16 align-middle"></i></button>
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickView(cell.id) }} data-toggle="modal" style={{ marginLeft: "10px" }}><i className="bx bx-edit font-size-16 align-middle"></i></button>
            </div>
        );
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "topup") {
            settopup_modal(false)
        }
    }

    function handleClickView(id) {
        const numberFormat = (value) =>
            new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR'
            }).format(value).replace(',00', '');
        prepaid.view_prepaid(id).then(
            (data) => {
                var obj = data;
                setview_modal(!view_modal)
                removeBodyCss()
                Object.assign(obj, { price: numberFormat(data.price), })
                Object.assign(obj, { discount: numberFormat(data.h2h_management_profit.discounts), })
                Object.assign(obj, { profits: numberFormat(data.h2h_management_profit.profits), })
                Object.assign(obj, { admin_fee: numberFormat(data.h2h_management_profit.admin_fee), })
                Object.assign(obj, {
                    buyer_product_status: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.buyer_product_status)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.buyer_product_status)}
                        </span>
                    ),
                })
                Object.assign(obj, {
                    seller_product_status: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.seller_product_status)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.seller_product_status)}
                        </span>
                    ),
                })
                Object.assign(obj, {
                    multi: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.multi)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.multi)}
                        </span>
                    ),
                })
                Object.assign(obj, {
                    unlimited_stock: (
                        <span className={`badge-soft-${getStatusBadgeClass(data.unlimited_stock)} me-1 badge badge-secondary badge-pill`}>
                            {getStatusText(data.unlimited_stock)}
                        </span>
                    ),
                })
                setViewPrepaid(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_prepaid)
    };

    document.title = `Postpaid Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: "",
            bank: "",
            owner_name: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await SaldoServer.topup_server(values)
                handleClickClose("topup")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Product" breadcrumbItem="Postpaid Settings" />
                    <Row className="mb-3">
                        <Col md="10">
                            <button type="button" id="btnAdd" onClick={() => { handleClickSync() }} data-toggle="modal" className="btn btn-primary waves-effect btn-label waves-light"><i className="bx bx-sync label-icon"></i> Synchrinize</button>
                        </Col>
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Product Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.product_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Category
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.category}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Brand
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.brand}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Type
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.type}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Seller Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.seller_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Price
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.price}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Profit
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.profits}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Discount
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.discount}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Admin Fee
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.admin_fee}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                SKU Code
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.buyer_sku_code}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Buyer Product Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.buyer_product_status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Seller Product Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.seller_product_status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Unlimited Stock
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.unlimited_stock}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Stock
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.stock}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Multi
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.multi}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Start Cut Off
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.start_cut_off}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                End Cut Off
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.end_cut_off}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Description
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_prepaid.description}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_prepaid.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_prepaid.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default PrepaidSettings;
