import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { logoutUser } from "../../store/actions"
import authObject from "../../services/AuthService"

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    authObject.logout();
    dispatch(logoutUser());
    navigate('/panel/login');
  }, [dispatch, navigate]);
  return null;
}

export default Logout;
