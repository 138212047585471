import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_sales = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_orders?type=Online&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_sales = (param) => {
    return axiosApi
        .get(API_URL + "get_detail_orders/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_status = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "update_status_order/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const online_sale = {
    all_sales,
    view_sales,
    edit_status,
    API_URL,
};

export default online_sale;