import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_student = (size, page, search, class_id, school_year, section) => {
    return axiosApi
        .get(API_URL + `get_all_student?limit=${size}&page=${page}&search=${search}&section_name=${section}&class_id=${class_id}&school_year=${school_year}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_student = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_student", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_student = (bodyParam) => {
    return axiosApi
        .post(API_URL + "student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_student = (param) => {
    return axiosApi
        .get(API_URL + "get_student/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_student = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "student/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_student = (param) => {
    return axiosApi
        .delete(API_URL + "student/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_class = (section_name, classId, type) => {
    return axiosApi
        .get(API_URL + "get_class?section_name=" + section_name + "&class_id=" + classId + "&type=" + type, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const upload_student = (bodyParam) => {
    return axiosApi
        .post(API_URL + "upload_student", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const upload_student_process = (bodyParam) => {
    return axiosApi
        .post(API_URL + "process_import_student", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_grade_promotion = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "process_grade_promotion/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_change_class = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "process_change_class/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_repeat_class = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "process_repeat_class/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const process_graduation = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "process_graduation/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const student = {
    all_student,
    multi_delete_student,
    add_student,
    view_student,
    edit_student,
    delete_student,
    get_class,
    upload_student,
    upload_student_process,
    process_grade_promotion,
    process_change_class,
    process_repeat_class,
    process_graduation,
    API_URL,
};

export default student;