import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_class = (size, page, search, section) => {
    return axiosApi
        .get(API_URL + `get_all_class?limit=${size}&page=${page}&search=${search}&section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_section = (section) => {
    return axiosApi
        .get(API_URL + `get_section?section_name=${section}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_class = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_class", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_class = (bodyParam) => {
    return axiosApi
        .post(API_URL + "class", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_class = (param) => {
    return axiosApi
        .get(API_URL + "get_class/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_class = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "class/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_class = (param) => {
    return axiosApi
        .delete(API_URL + "class/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const Class = {
    all_class,
    get_section,
    multi_delete_class,
    add_class,
    view_class,
    edit_class,
    delete_class,
    API_URL,
};

export default Class;