import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_service = (size, page, search, category) => {
    return axiosApi
        .get(API_URL + `get_all_service?limit=${size}&page=${page}&search=${search}&category=${category}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_service = (bodyParam) => {
    return axiosApi
        .post(API_URL + "service_mobile", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_service = (param) => {
    return axiosApi
        .get(API_URL + "get_service_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_service = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "service_mobile/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_service = (param) => {
    return axiosApi
        .delete(API_URL + "service_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_service = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_service_mobile", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const service = {
    all_service,
    multi_delete_service,
    delete_service,
    add_service,
    view_service,
    edit_service,
    API_URL,
};

export default service;