import { axiosApi, API_URL } from "../helpers/api_helper"

const login = (bodyParam, props) => {
    return axiosApi
        .post(API_URL + "login", bodyParam)
        .then((response) => {
            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("webConfig");
};

const authObject = {
    login,
    logout,
    API_URL,
};

export default authObject;