import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_history = (start_date, end_date, size, page, search) => {
    return axiosApi
        .get(API_URL + `list_history_saldo?start_date=${start_date}&end_date=${end_date}&limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const History_saldo = {
    all_history,
    API_URL,
};

export default History_saldo;