import React from "react";
import { Button, Input, Row, Col } from "reactstrap";
import PropTypes from 'prop-types';

const DataTableContainer = ({
    isFilter,
    searchTerm,
    fieldName,
    handleSearch,
}) => {
    return (
        <React.Fragment>
            {isFilter && (
                <Col md="2">
                    <div className="search-box me-3 mb-3 d-inline-block">
                        <div className="position-absolute">
                            <Input
                                type="text"
                                placeholder={fieldName ? `Search by ${fieldName}` : "Search"}
                                value={searchTerm}
                                onChange={handleSearch}
                                className="ml-auto form-control-sm"
                                style={{ width: '200px', height: '35px' }}
                            />
                            <i className="bx bx-search-alt search-icon" />
                        </div>
                    </div>
                </Col>
            )}
        </React.Fragment>
    );
};

DataTableContainer.propTypes = {
    isFilter: PropTypes.bool,
    searchTerm: PropTypes.string,
    handleSearch: PropTypes.func,
};

export default DataTableContainer;
