import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const config = () => {
    return axiosApi
        .get(API_URL + "config")
        .then((response) => {
            return response.data;
        });
};

const view_config = (param) => {
    return axiosApi
        .get(API_URL + "get_config/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_config = (bodyParam) => {
    return axiosApi
        .put(API_URL + "config", bodyParam, { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const configObject = {
    config,
    view_config,
    edit_config
};

export default configObject;