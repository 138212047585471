import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Col,
  Row
} from 'reactstrap'
import FeatherIcon from "feather-icons-react";
import PropTypes from 'prop-types';

const Pagination = ({ isPageSize, isPagination, onSizeChange, currentPage, totalPages, onPageChange }) => {
  const [dropup1, setDropup1] = useState(false);
  const [selectedValue, setSelectedValue] = useState('10');

  const getPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = startPage + maxPagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setDropup1(false);
    onSizeChange(value)
  };

  const pageNumbers = getPageNumbers();

  return (
    <React.Fragment>
      <Row className="mb-3" style={{ borderTop: "1px solid #dee2e6", paddingTop: "20px", }}>
        <Col md="8">
          {isPageSize && (
            <Dropdown
              className="btn-group dropup"
              isOpen={dropup1}
              direction="up"
              toggle={() => setDropup1(!dropup1)}
            >
              <DropdownToggle className="btn btn-primary dropdown-toggle">
                {selectedValue} <i className="mdi mdi-chevron-up" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleSelect('10')}>10</DropdownItem>
                <DropdownItem onClick={() => handleSelect('25')}>25</DropdownItem>
                <DropdownItem onClick={() => handleSelect('50')}>50</DropdownItem>
                <DropdownItem onClick={() => handleSelect('100')}>100</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </Col>
        <Col md="4" className="d-flex justify-content-end">
          {isPagination && (
            <nav>
              <ul className="pagination">
                {currentPage > 1 && currentPage !== 2 && currentPage !== 3 && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(1)}><FeatherIcon icon="chevrons-left" style={{ height: '17px' }} /></button>
                  </li>
                )}
                {currentPage > 1 && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(currentPage - 1)}><FeatherIcon icon="chevron-left" style={{ height: '15px' }} /></button>
                  </li>
                )}
                {pageNumbers.map(number => (
                  <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => onPageChange(number)}>{number}</button>
                  </li>
                ))}
                {currentPage < totalPages && currentPage !== totalPages && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(currentPage + 1)}><FeatherIcon icon="chevron-right" style={{ height: '15px' }} /></button>
                  </li>
                )}
                {currentPage < totalPages - 2 && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => onPageChange(totalPages)}><FeatherIcon icon="chevrons-right" style={{ height: '17px' }} /></button>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

Pagination.propTypes = {
  onSizeChange: PropTypes.func,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  onPageChange: PropTypes.func
};

export default Pagination;
