import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_logo_product = (size, page, search) => {
    return axiosApi
        .get(API_URL + `list_logo_product?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_logo_product = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_logo_product", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_logo_product = (param) => {
    return axiosApi
        .delete(API_URL + "logo_product/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_logo_product = (bodyParam) => {
    return axiosApi
        .post(API_URL + "logo_product", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_logo_product = (param) => {
    return axiosApi
        .get(API_URL + "get_logo_product/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_logo_product = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "logo_product/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const logoProduct = {
    all_logo_product,
    multi_delete_logo_product,
    delete_logo_product,
    add_logo_product,
    view_logo_product,
    edit_logo_product,
    API_URL,
};

export default logoProduct;