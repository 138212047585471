import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_banner = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_banner?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_banner = (bodyParam) => {
    return axiosApi
        .post(API_URL + "banner_mobile", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_banner = (param) => {
    return axiosApi
        .get(API_URL + "get_banner_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_banner = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "banner_mobile/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const delete_banner = (param) => {
    return axiosApi
        .delete(API_URL + "banner_mobile/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_banner = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_banner_mobile", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const banner = {
    all_banner,
    multi_delete_banner,
    delete_banner,
    add_banner,
    view_banner,
    edit_banner,
    API_URL,
};

export default banner;