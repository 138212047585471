import Swal from 'sweetalert2';

const swalCustoms = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-lg btn-success mx-1",
    cancelButton: "btn btn-lg btn-danger mx-1"
  },
  buttonsStyling: false
});

export const ModalConfirm = (
  title,
  text,
  icon,
  pageName,
  confirmCallback
) => {
  swalCustoms.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback()
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalCustoms.fire({
        title: "Cancelled",
        text: `Your ${pageName} data is safe :)`,
        icon: "error"
      });
    }
  });
}

export const ModalConfirmation = (
  title,
  text,
  icon,
  pageName,
  confirmCallback
) => {
  swalCustoms.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: "Yes, process it!",
    cancelButtonText: "No, cancel!",
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      confirmCallback()
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalCustoms.fire({
        title: "Cancelled",
        text: `Your ${pageName} data is safe :)`,
        icon: "error"
      });
    }
  });
}

export const ModalInfo = (
  title,
  text,
  icon,
  isReload
) => {
  swalCustoms.fire({
    title: title,
    text: text,
    icon: icon
  }).then((result) => {
    if (result.isConfirmed) {
      if (isReload) {
        window.location.reload()
      }
    }
  })
}