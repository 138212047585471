import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const authUser = JSON.parse(localStorage.getItem("authUser"))
const category_menu = () => {
    return axiosApi
        .get(API_URL + "list_category_menu", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const menu = () => {
    return axiosApi
        .get(API_URL + "get_list_menu?roles=" + authUser.roles, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const cmsObject = {
    category_menu,
    menu,
    API_URL,
};

export default cmsObject;