import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_users = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_mob_users?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_users = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_mob_users", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const view_users = (param) => {
    return axiosApi
        .get(API_URL + "get_mob_users/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_users = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "mob_users/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const topup_saldo = (bodyParam) => {
    console.log(bodyParam);
    return axiosApi
        .post(API_URL + "topup_by_admin", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_users = (param) => {
    return axiosApi
        .delete(API_URL + "mob_users/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const get_roles = (param) => {
    return axiosApi
        .get(API_URL + "get_mob_roles", { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};


const role = {
    all_users,
    view_users,
    edit_users,
    topup_saldo,
    delete_users,
    multi_delete_users,
    get_roles,
    API_URL,
};

export default role;