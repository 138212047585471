import React, { useEffect, useState } from "react"
import { Container, Button, Input, Row, Col, Modal, Form, Label } from "reactstrap"
import ButtonContainer from "../../components/Common/ButtonContainer"
import SearchContainer from "../../components/Common/SearchContainer"
import DataTableContainer from "../../components/Common/DataTableContainer"
import Pagination from "../../components/Common/Pagination"
import Moment from "moment"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import kindness from "../../services/MenuKindnessService"
import banner from "../../services/BannerService"
import { useFormik } from "formik"
import { ModalConfirm, ModalInfo } from "../../components/Common/ModalCustoms"
import { IMAGE_URL } from "../../helpers/api_helper"

const BannerSettings = () => {
    const [list_banner, setListBanner] = useState([])
    const [view_banner, setViewBanner] = useState("")
    const [edit_banner, setEditBanner] = useState("")
    const [view_modal, setview_modal] = useState(false)
    const [add_modal, setadd_modal] = useState(false)
    const [edit_modal, setedit_modal] = useState(false)
    const [image, setImage] = useState("")
    const [imageEdit, setImageEdit] = useState("")
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        var btnDelete = document.getElementById("btnDelete")
        btnDelete.style.visibility = "hidden"
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const initData = async (size, page, search) => {
        try {
            const result = await banner.all_banner(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                images: <img src={IMAGE_URL + item.banner_image} alt="" className="img-fluid" />,
                status: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.is_active)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.is_active)}
                    </span>
                ),
                created_date: Moment(item.created_date).utc().format('yyyy-MM-DD hh:mm:ss'),
                updated_date: Moment(item.updated_date).utc().format('yyyy-MM-DD hh:mm:ss'),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListBanner(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                // window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 2:
                return "success"
            case 3:
                return "primary"
            case 1:
                return "dark"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 2:
                return "Active"
            case 3:
                return "Coming Soon"
            case 1:
                return "Not Active"
            default:
                return "Unknown"
        }
    };

    const checkedBoxesFormatter = (row) => {
        return (
            <div style={{ textAlign: "center" }}>
                <input type="checkbox" value={row.id} onClick={() => { handleClickCheckboxes(row.id) }} />
            </div>
        )
    };

    const rankFormatter = (row) => {
        return (
            <div style={{ textAlign: "left" }}>
                <button type="button" className="btn btn-soft-dark waves-effect waves-light" onClick={() => { handleClickView(row.id) }} data-toggle="modal">
                    <i className="bx bx-show font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-primary waves-effect waves-light" onClick={() => { handleClickEdit(row.id) }} data-toggle="modal">
                    <i className="bx bx-edit font-size-16 align-middle"></i>
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" className="btn btn-soft-danger waves-effect waves-light" onClick={() => { handleClickDeleteOne(row.id) }}>
                    <i className="bx bx-trash font-size-16 align-middle"></i>
                </button>
            </div>
        )
    };

    function handleClickCheckboxes() {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        var btnDelete = document.getElementById("btnDelete")
        if (checkedBoxes.length > 1) {
            btnDelete.style.visibility = 'visible';
        } else if (checkedBoxes.length < 1) {
            btnDelete.style.visibility = 'hidden';
        } else {
            btnDelete.style.visibility = 'hidden';
        }
    }

    const columns = [
        {
            name: '#',
            selector: row => row.checkbox,
            sortable: false,
            cell: checkedBoxesFormatter,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '5%',
        },
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'Banner Name',
            selector: row => row.banner_name,
            sortable: true,
            width: '15%',
        },
        {
            name: 'Image',
            selector: row => row.images,
            width: '7%',
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: '18%',
        },
        {
            name: 'Banner Action',
            selector: row => row.banner_action,
            sortable: true,
            width: '12%',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '8%',
        },
        {
            name: 'Updated Date',
            selector: row => row.updated_date,
            sortable: true,
            width: '14%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
        document.getElementById("btnDelete").style.visibility = "hidden"
    };

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
        setImage("")
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "edit") {
            setedit_modal(false)
        } else {
            setadd_modal(false)
        }
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickView = async (id) => {
        try {
            const data = await banner.view_banner(id)

            // Clone the data to avoid direct mutation
            const updatedData = { ...data };

            // Update status based on is_active
            if (data.is_active === 2) {
                updatedData.status = <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Active </span>;
            } else if (data.is_active === 3) {
                updatedData.status = <span className='badge-soft-primary me-1 badge badge-secondary badge-pill'> Coming Soon </span>;
            } else if (data.is_active === 1) {
                updatedData.status = <span className='badge-soft-dark me-1 badge badge-secondary badge-pill'> Not Active </span>;
            }

            setViewBanner(updatedData)

            setview_modal(!view_modal)
            removeBodyCss()
        } catch (error) {
            const errorCode = error.response?.data?.status;

            if (errorCode === 401) {
                window.location.replace('/panel/logout')
            } else if (errorCode === 403) {
                // window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickEdit = (id) => {
        banner.view_banner(id).then(
            (data) => {
                setedit_modal(!edit_modal)
                setEditBanner(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status)
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    // window.location.reload()
                }
            }
        )
    };

    const handleClickDeleteSelected = () => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "banner settings",
            () => processClickDeleteSelected(),
        )
    };

    const processClickDeleteSelected = async () => {
        let checkedBoxes = document.querySelectorAll('input[type=checkbox]:checked')
        let arrayCheck = Array.from(checkedBoxes).map(checkbox => Number.parseInt(checkbox.value))

        if (arrayCheck.length === 0) {
            ModalInfo(
                "No selection",
                "Please select at least one item to delete.",
                "warning",
                false
            )
            return;
        }

        try {
            await banner.multi_delete_banner({ id: arrayCheck })
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
            document.getElementById("btnDelete").style.visibility = "hidden"
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                // window.location.reload()
            } else {
                ModalInfo(
                    "Error",
                    "An unexpected error occurred.",
                    "error",
                    false
                )
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleClickDeleteOne = (id) => {
        ModalConfirm(
            "Are you sure?",
            "You won't be able to revert this!",
            "warning",
            "banner settings",
            () => processClickDeleteOne(id),
        )
    };

    const processClickDeleteOne = async (id) => {
        try {
            await banner.delete_banner(id)
            ModalInfo(
                "Deleted!",
                "Your data has been deleted.",
                "success",
                false
            )
            initData(currentSize, currentPage, searchTerm)
        } catch (error) {
            const errorCode = JSON.stringify(error.response?.data?.status)
            if (errorCode === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode === "403") {
                // window.location.reload()
            } else {
                console.error('An unexpected error occurred:', error)
            }
        }
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_banner)
    };

    document.title = `Banner Settings | ${webConfig[0].name} - ${webConfig[0].title}`;

    const handleImageAddChange = (event) => {
        const file = event.target.files[0];
        setImage(file)
        validationAddType.setFieldValue('banner_image', file)
    };

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            banner_name: "",
            banner_image: "",
            description: "",
            banner_action: "",
            is_active: "",
        },
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData()
            formData.append('banner_name', values.banner_name)
            formData.append('banner_image', values.banner_image)
            formData.append('description', values.description)
            formData.append('banner_action', values.banner_action)
            formData.append('is_active', values.is_active)
            try {
                await banner.add_banner(formData)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    // window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    const handleImageEditChange = (event) => {
        const file = event.target.files[0];
        setImageEdit(file)
        validationEditType.setFieldValue('banner_image', file)
    };

    const validationEditType = useFormik({
        enableReinitialize: true,
        initialValues: {
            banner_name: edit_banner.banner_name,
            banner_image: edit_banner.banner_image,
            image_path: edit_banner.banner_image,
            description: edit_banner.description,
            banner_action: edit_banner.banner_action,
            is_active: edit_banner.is_active,
        },
        onSubmit: async (values, { resetForm }) => {
            const formData = new FormData()
            formData.append('banner_name', values.banner_name)
            formData.append('banner_image', imageEdit)
            formData.append('image_path', values.image_path)
            formData.append('description', values.description)
            formData.append('banner_action', values.banner_action)
            formData.append('is_active', values.is_active)
            try {
                await banner.edit_banner(edit_banner.id, formData)
                handleClickClose("edit")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    // window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Banner Settings" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            textButtonAdd="Tambah"
                            isButtonDelete={true}
                            handleClickAdd={handleClickAdd}
                            handleClickDeleteSelected={handleClickDeleteSelected}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            fieldName={"Banner Name"}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Add
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Banner Name</Label>
                                <Input
                                    type="text"
                                    name="banner_name"
                                    id="banner_name"
                                    placeholder="Enter banner name"
                                    value={validationAddType.values.banner_name}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                {image && <img className="preview" src={URL.createObjectURL(image)} alt="preview" style={{ height: "50px" }} />}<br /><br />
                                <Label className="form-label">Image</Label>
                                <Input
                                    type="file"
                                    name="banner_image"
                                    id="banner_image"
                                    placeholder="Enter banner image"
                                    onChange={handleImageAddChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter description"
                                    value={validationAddType.values.description}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Banner Action</Label>
                                <Input
                                    type="text"
                                    name="banner_action"
                                    id="banner_action"
                                    placeholder="Enter banner action"
                                    value={validationAddType.values.banner_action}
                                    onChange={validationAddType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationAddType.values.is_active || ''}
                                    onChange={validationAddType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={edit_modal}
                    toggle={() => {
                        handleClickEdit()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Edit
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("edit")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationEditType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">Banner Name</Label>
                                <Input
                                    type="text"
                                    name="banner_name"
                                    id="banner_name"
                                    placeholder="Enter banner name"
                                    value={validationEditType.values.banner_name || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <Input
                                type="hidden"
                                name="image_path"
                                id="image_path"
                                value={validationEditType.values.image_path || ''}
                                onChange={validationEditType.handleChange}
                                required
                            />
                            <div className="mb-3">
                                {(imageEdit && <img className="preview" src={URL.createObjectURL(imageEdit)} alt="preview" style={{ height: "50px" }} />) || (validationEditType.values.banner_image && <img className="preview" src={IMAGE_URL + validationEditType.values.banner_image} alt="preview" style={{ height: "50px" }} />)}<br /><br />
                                <Label className="form-label">Image</Label>
                                <Input
                                    type="file"
                                    name="banner_image"
                                    id="banner_image"
                                    placeholder="Enter banner image"
                                    onChange={handleImageEditChange}
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Description</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    id="description"
                                    placeholder="Enter description"
                                    value={validationEditType.values.description || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Banner Action</Label>
                                <Input
                                    type="text"
                                    name="banner_action"
                                    id="banner_action"
                                    placeholder="Enter banner action"
                                    value={validationEditType.values.banner_action || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Status</Label>
                                <Input
                                    type="select"
                                    name="is_active"
                                    id="is_active"
                                    value={validationEditType.values.is_active || ''}
                                    onChange={validationEditType.handleChange}
                                    required
                                >
                                    <option value="">Select Status</option>
                                    <option value="1">Not Active</option>
                                    <option value="2">Active</option>
                                </Input>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Update</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("edit")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="4">
                                ID
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_banner.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Banner Name
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_banner.banner_name}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Banner Image
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                <img src={IMAGE_URL + view_banner.banner_image} alt="" className="img-fluid" />
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Description
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_banner.description}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Banner Action
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_banner.banner_action}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Status
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {view_banner.status}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Created Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_banner.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="4">
                                Updated Date
                            </Col>
                            <Col xl="1" style={{ textAlign: "right" }}>
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_banner.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </React.Fragment >
    )
};

export default BannerSettings;
