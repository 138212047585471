import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_bank = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_transfer_bank?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_bank = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_transfer_bank", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const delete_bank = (param) => {
    return axiosApi
        .delete(API_URL + "transfer_bank/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const add_bank = (bodyParam) => {
    return axiosApi
        .post(API_URL + "transfer_bank", bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const view_bank = (param) => {
    return axiosApi
        .get(API_URL + "get_transfer_bank/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_bank = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "transfer_bank/" + param, bodyParam, { headers: authHeader, 'Content-Type': 'multipart/form-data' })
        .then((response) => {
            return response.data;
        });
};

const transferBank = {
    all_bank,
    multi_delete_bank,
    delete_bank,
    add_bank,
    view_bank,
    edit_bank,
    API_URL,
};

export default transferBank;