import React, { useEffect, useState } from "react";
import { Container, Input, Row, Col, Modal, Form, Label } from "reactstrap";
import ButtonContainer from "../../components/Common/ButtonContainer";
import SearchContainer from "../../components/Common/SearchContainer";
import DataTableContainer from "../../components/Common/DataTableContainer";
import Pagination from "../../components/Common/Pagination";
import Moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import offline_sale from "../../services/OfflineSaleService"
import { useFormik } from "formik"
import QRCode from 'qrcode.react';
import { MD5 } from 'crypto-js';

const OnlineSaleList = () => {

    const generateRandomDigits = () => {
        return Math.floor(1000 + Math.random() * 9000);
    };

    const generateSalesID = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const randomDigits = generateRandomDigits();

        return `${year}${month}${day}${randomDigits}`;
    };

    const generateDateTime = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const [list_sales, setListSales] = useState([])
    const [view_sales, setViewSales] = useState("");
    const [view_modal, setview_modal] = useState(false);
    const [add_modal, setadd_modal] = useState(false);
    const [no_sales] = useState(generateSalesID());
    const [tanggal] = useState(generateDateTime());
    const [amount, setAmount] = useState('');
    const [filtered, setFiltered] = useState([])
    const [searchTerm, setSearchTerm] = useState('')
    const [currentSize, setCurrentSize] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(1)
    const webConfig = JSON.parse(localStorage.getItem("webConfig"))

    const itemListStyle = {
        margin: '20px'
    };

    const itemStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        paddingBottom: '10px',
        position: 'relative'
    };

    const itemImageStyle = {
        width: '60px',
        height: '60px',
        marginRight: '10px'
    };

    const itemDetailsStyle = {
        display: 'flex',
        flexDirection: 'column'
    };

    const itemNameStyle = {
        fontSize: '14px',
        fontWeight: 'bold'
    };

    const itemPriceQuantityStyle = {
        fontSize: '13px'
    };

    const hrStyle = {
        position: 'absolute',
        left: '10px',
        right: '10px',
        bottom: '-17px',
        borderBottom: '1px solid #ccc'
    };

    if (!localStorage.getItem("authUser").includes("ROLE_DEVELOPER") && !localStorage.getItem("authUser").includes("ROLE_ADMIN") && !localStorage.getItem("authUser").includes("ROLE_KASIR")) {
        window.location.replace('/panel/error-403')
    }

    useEffect(() => {
        const controller = new AbortController()
        initData(10, 1, searchTerm)
        return () => controller.abort()
    }, [])

    const numberFormat = (value) =>
        new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
        }).format(value).replace(',00', '');

    const initData = async (size, page, search) => {
        try {
            const result = await offline_sale.all_sales(size, page, search)
            const formattedData = result.data.map((item, index) => ({
                ...item,
                no: <div style={{ textAlign: "center" }}>{item.no}</div>,
                tanggal: Moment(item.tanggal).utc().format('yyyy-MM-DD hh:mm:ss'),
                total_payment: numberFormat(item.total_payment),
                status_pay: (
                    <span className={`badge-soft-${getStatusBadgeClass(item.status_pay)} me-1 badge badge-secondary badge-pill`}>
                        {getStatusText(item.status_pay)}
                    </span>
                ),
            }))
            setPerPage(result.total_pages)
            setCurrentPage(result.current_page)
            setListSales(formattedData)
            setFiltered(formattedData)
        } catch (error) {
            const errorCode = JSON.stringify(error.response.data.status)
            if (errorCode.toString() === "401") {
                window.location.replace('/panel/logout')
            } else if (errorCode.toString() === "403") {
                window.location.reload()
            }
        }
    };

    const getStatusBadgeClass = (status) => {
        switch (status) {
            case 1:
                return "primary"
            case 3:
                return "success"
            default:
                return "secondary"
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return "Menunggu Pembayaran"
            case 3:
                return "Pesanan Diterima"
            default:
                return "Unknown"
        }
    }

    const columns = [
        {
            name: 'No.',
            selector: row => row.no,
            sortable: true,
            center: "true",
            style: {
                textAlign: 'center',
            },
            width: '7%',
        },
        {
            name: 'ID Penjualan',
            selector: row => row.no_sales,
            sortable: true,
            width: '30%',
        },
        {
            name: 'Tanggal',
            selector: row => row.tanggal,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Total Bayar',
            selector: row => row.total_payment,
            sortable: true,
            width: '20%',
        },
        {
            name: 'Status',
            selector: row => row.status_pay,
            sortable: true,
            width: '14%',
        },
        {
            name: "Actions",
            selector: row => row.actions,
            sortable: false,
            cell: rankFormatter,
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        let buttonIcon;
        let buttonClass;
        if (row.status === 3) {
            buttonIcon = <i className="bx bx-show font-size-16 align-middle"></i>;
            buttonClass = "btn btn-secondary waves-effect waves-light";
        } else {
            buttonIcon = <i className="bx bx-send font-size-16 align-middle"></i>;
            buttonClass = "btn btn-primary waves-effect waves-light";
        }
        return (
            <div
                style={{
                    textAlign: "left",
                }}>
                <button type="button" className={buttonClass} onClick={() => { handleClickView(cell.id) }} data-toggle="modal">{buttonIcon}</button>
            </div>
        );
    }

    const handleChengeAmountAdd = (e) => {
        const rawValue = e.target.value;
        const formattedValue = formatCurrency(rawValue);
        setAmount(formattedValue);
        const priceReplace = formattedValue.replace(/\./g, '');
        validationAddType.setFieldValue("total_payment", priceReplace)
    }

    const formatCurrency = (value) => {
        const numericValue = value.replace(/[^0-9]/g, '');
        const formattedValue = Number(numericValue).toLocaleString('id-ID');
        return formattedValue;
    };

    function handleClickView(id) {
        offline_sale.view_sales(id).then(
            (data) => {
                var obj = data;
                setview_modal(!view_modal)
                removeBodyCss()
                let md5Value = MD5(obj.no_sales.toString() + "#" + obj.total_payment.toString()).toString();
                Object.assign(obj, { qrcode: obj.no_sales.toString() + "#" + obj.total_payment.toString() + "#" + md5Value })
                Object.assign(obj, { total_payment_rep: numberFormat(obj.total_payment) })
                if (data.status_pay === 1) {
                    Object.assign(obj, { status_pay: <span className='badge-soft-secondary me-1 badge badge-secondary badge-pill'> Menunggu Pembayaran </span> });
                } else if (data.is_active === 3) {
                    Object.assign(obj, { status_pay: <span className='badge-soft-success me-1 badge badge-secondary badge-pill'> Selesai </span> });
                }
                setViewSales(data)
                return Promise.resolve()
            },
            (error) => {
                const errorCode = JSON.stringify(error.response.data.status);
                if (errorCode.toString() === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode.toString() === "403") {
                    window.location.reload()
                }
            }
        )
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    const handleClickAdd = () => {
        setadd_modal(!add_modal)
        validationAddType.resetForm()
    };

    function handleClickClose(action) {
        if (action === "view") {
            setview_modal(false)
        } else if (action === "add") {
            setadd_modal(false)
        }
    }

    const handleSizeChange = size => {
        setCurrentSize(size)
        initData(size, currentPage, searchTerm)
    };

    const handlePageChange = page => {
        setCurrentPage(page)
        initData(currentSize, page, searchTerm)
    };

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        initData(currentSize, currentPage, event.target.value)
        setFiltered(list_sales)
    };

    const validationAddType = useFormik({
        enableReinitialize: true,
        initialValues: {
            no_sales: no_sales,
            tanggal: tanggal,
            total_payment: ""
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                await offline_sale.add_sales(values)
                handleClickClose("add")
                initData(currentSize, currentPage, searchTerm)
                resetForm()
            } catch (error) {
                const errorCode = JSON.stringify(error.response?.data?.status)
                if (errorCode === "401") {
                    window.location.replace('/panel/logout')
                } else if (errorCode === "403") {
                    window.location.reload()
                } else {
                    console.error('An unexpected error occurred:', error)
                }
            }
        }
    })

    document.title = `History Penjualan Offline | ${webConfig[0].name} - ${webConfig[0].title}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Penjualan Offline" />
                    <Row className="mb-3">
                        <ButtonContainer
                            isButtonAdd={true}
                            textButtonAdd="Tambah Penjualan"
                            handleClickAdd={handleClickAdd}
                        />
                        <SearchContainer
                            isFilter={true}
                            searchTerm={searchTerm}
                            handleSearch={handleSearch}
                        />
                    </Row>
                    <DataTableContainer
                        columns={columns}
                        data={filtered}
                        currentPage={currentPage}
                        perPage={perPage}
                        handlePageChange={handlePageChange}
                        searchTerm={searchTerm}
                        handleSearch={handleSearch}
                    />

                    <Pagination
                        isPageSize={true}
                        isPagination={true}
                        currentPage={currentPage}
                        totalPages={perPage}
                        onPageChange={handlePageChange}
                        onSizeChange={handleSizeChange}
                    />
                </Container>
                <Modal
                    isOpen={add_modal}
                    toggle={() => {
                        handleClickAdd()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            Tambah Penjualan
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("add")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault()
                            validationAddType.handleSubmit()
                            return false;
                        }}
                    >
                        <div className="modal-body">
                            <div className="mb-3">
                                <Label className="form-label">ID Penjualan :</Label>
                                <Input
                                    type="text"
                                    name="no_sales"
                                    id="no_sales"
                                    placeholder="Enter sales ID"
                                    value={no_sales}
                                    onChange={validationAddType.handleChange}
                                    style={{
                                        backgroundColor: 'lightgray',
                                        cursor: 'not-allowed'
                                    }}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Tanggal Penjualan :</Label>
                                <Input
                                    type="text"
                                    name="tanggal"
                                    id="tanggal"
                                    placeholder="Enter tanggal"
                                    value={tanggal}
                                    onChange={validationAddType.handleChange}
                                    style={{
                                        backgroundColor: 'lightgray',
                                        cursor: 'not-allowed'
                                    }}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Total Belanja :</Label>
                                <Input
                                    type="text"
                                    name="total_payment"
                                    id="total_payment"
                                    placeholder="Enter total payment"
                                    value={amount}
                                    maxLength={13}
                                    onChange={handleChengeAmountAdd}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" type="submit">Save</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("add")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    isOpen={view_modal}
                    toggle={() => {
                        handleClickView()
                    }}
                    backdrop={'static'}
                    id="staticBackdrop"
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0">
                            View
                        </h5>
                        <button
                            type="button"
                            onClick={() =>
                                handleClickClose("view")
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <Col xl="3">
                                ID
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.id}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                No Penjualan
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                #{view_sales.no_sales}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                tanggal
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.tanggal}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Total Bayar
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.total_payment}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Tipe
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.type}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Status
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {view_sales.status_pay}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Created Date
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_sales.created_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                        </div>
                        <div className="row">
                            <Col xl="3">
                                Updated Date
                            </Col>
                            <Col xl="1">
                                :
                            </Col>
                            <Col xl="7">
                                {Moment(view_sales.updated_date).utc().format('yyyy-MM-DD hh:mm:ss')}
                            </Col>
                            <br /><br />
                        </div>
                        <div className="row">
                            <Col xl="5">
                                <strong>Scan Untuk Membayar :</strong>
                            </Col>
                        </div>
                        <br />
                        <div className="row justify-content-center">
                            <Col xl="4">
                                <div className="d-flex justify-content-center">
                                    <QRCode value={view_sales.qrcode} />
                                </div>
                            </Col>
                        </div>
                        <br />
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() =>
                                    handleClickClose("view")
                                }
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Modal>
            </div >
        </React.Fragment >
    )
};

export default OnlineSaleList;
